<template>
    <div class="card-equipo d-flex cr-pointer">
        <div class="cont-img border br-4 wh-151">
            <img :src="img | helper-storage" alt="" class="obj-cover h-100 w-100 br-4">
        </div>
        <div class="ml-3 my-auto tres-puntos">
            <p class="f-16 f-700 mb-3 tres-puntos"> {{ name }} </p>
            <p class="f-14 f-600 mb-2">
                Precio:
                <span class="f-300"> {{ formatoMoneda(price) }} </span>
                <!-- <i class="icon-alert text-naranja f-18 mb-2" /> -->
            </p>
            <p class="f-14 f-600 mb-2">
                Etapas:
                <span class="f-300"> {{ cantPhases }} </span>
            </p>
            <p class="f-14 f-600 mb-2">
                Materiales:
                <span class="f-300"> {{ cantMaterials }} </span>
            </p>
            <p class="f-14 f-600 mb-2">
                Horas hombre:
                <span class="f-300"> {{ cantHours }} </span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        img:{
            type: String,
        },
        name:{
            type: String,
        },
        price:{
            type: [String, Number],
        },
        cantPhases:{
            type: Number,
        },
        cantMaterials:{
            type: Number,
        },
        cantHours:{
            type: Number,
        },
    }

}
</script>
<style lang="scss" scoped>
.card-etapa-general{
    background: #F9F9F9;
    border: 1px solid #DDDDDD;
}
</style>